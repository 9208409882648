<template>
  <div class="Social">
    <Go
      v-for="(link, platform) of socials"
      :key="platform"
      :to="link"
      :aria-label="platform"
    >
      <component :is="platform" />
    </Go>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    bandcamp: defineAsyncComponent(() => import('@/assets/images/bandcamp-brands.svg')),
    facebook: defineAsyncComponent(() => import('@/assets/images/facebook-f-brands.svg')),
    instagram: defineAsyncComponent(() => import('@/assets/images/instagram-brands.svg')),
    applemusic: defineAsyncComponent(() => import('@/assets/images/itunes-note-brands.svg')),
    soundcloud: defineAsyncComponent(() => import('@/assets/images/soundcloud-brands.svg')),
    spotify: defineAsyncComponent(() => import('@/assets/images/spotify-brands.svg')),
    twitter: defineAsyncComponent(() => import('@/assets/images/twitter-brands.svg')),
    vimeo: defineAsyncComponent(() => import('@/assets/images/vimeo-v-brands.svg')),
    youtube: defineAsyncComponent(() => import('@/assets/images/youtube-brands.svg')),
  },
  props: { payload: { type: Object, default: Object } },
  computed: {
    socials() {
      const socials = {};
      for (const key of Object.keys(this.$options.components)) {
        if (this.payload[key]) {
          socials[key] = this.payload[key];
        }
      }
      return socials;
    },
  },
};
</script>

<style scoped lang="scss">
.Social {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  a {
    @apply rounded;
    background: currentColor;
  }
  svg {
    color: white;
    width: 2rem;
    height: 2rem;
    padding: 0.4rem;

    @screen md {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
</style>
