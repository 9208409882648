<template>
  <div id="nav">
    <div class="constrain">
      <div class="social ontop">
        <Social v-if="layout" :payload="layout?.generell" />
      </div>

      <Hamburger
        class="md:hidden ontop my-0"
        :class="{ active: mobile }"
        @click="toggle()"
      />

      <nav class="row-12 w-full">
        <div class="col-mid desktop">
          <Go
            v-for="link of navigation?.links"
            :key="link.uri"
            :to="link.uri"
            class="link"
            :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
            v-text="link.title"
          />
          <div v-if="!page.meta?.home" to="#filter">
            <div class="home-filter mt-8">
              <Go
                v-for="tag of navigation?.filter"
                :key="tag.id"
                :to="`/${$store.state.router.locale}?tag=${tag.slug}`"
                class="filter"
                :class="['bg-' + tag.farbe]"
                v-text="tag.title"
              />
            </div>
          </div>
        </div>

        <div
          class="col-mid"
          :class="{ hidden: !page.meta?.home }"
        >
          <div id="filter" class="mt-4 md:mt-8" />
        </div>
      </nav>
    </div>
  </div>

  <div class="mobile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav constrain">

        <div class="home-filter mb-8">
          <Go
            v-for="tag of navigation?.filter"
            :key="tag.id"
            :to="`/${$store.state.router.locale}?tag=${tag.slug}`"
            class="filter"
            :class="['bg-' + tag.farbe]"
            @click="toggle(true)"
            v-text="tag.title"
          />
        </div>

        <Go
          v-for="link of navigation?.links"
          :key="link.uri"
          :to="link.uri"
          class="link"
          :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
          @click="toggle(true)"
          v-text="link.title"
        />
      </div>
    </transition-group>
  </div>

</template>

<script>

import Scrolllock from '@/hooks/scrolllock';
import Hamburger from '../utils/Hamburger.vue';
import Social from '../utils/Social.vue';

export default {
  components: {
    Hamburger,
    Social,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    layout() {
      return this.$store.state.router.layout;
    },
    page() {
      return this.$store.state.router.page;
    },
  },
  methods: {
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  padding-bottom: 1rem;
}

.ontop {
  position: relative;
  z-index: 100;
}

.link {
  font-size: px(25);
  font-weight: $heavy;
  border-bottom: 0.15em solid currentColor;
  margin-right: 1rem;

  @include hover {
    color: black;
    border-bottom-color: var(--color-blue);
  }

  &.active {
    @apply text-blue;
  }
}

.social {
  @apply mt-4 float-right;
}

.desktop {
  display: none;
  @screen md {
    display: block;
  }
}

@screen md {
  .backdrop, .mobile-nav {
    display: none;
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: absolute;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;

  .link {
    font-size: 1.5rem;
    padding: 0.8rem 0;
    text-decoration: none !important;
  }

  .filter {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    width: calc(50% - 0.5rem);
  }
}
</style>
