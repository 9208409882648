<template>
  <footer class="Footer mt-auto pt-16 pb-4">
    <div class="constrain">
      <div class="row-12 items-center">

        <div class="col-12 md:col-2 lg:offset-1 xl:offset-2">
          <Go :to="`/${$store.state.router.locale}`">
            <Image :src="generell?.logo" />
          </Go>
        </div>

        <div class="col-12 md:col-10 md:text-right lg:col-9 lg:col-8 mt-4 md:mt-0">
          <div v-html="generell?.slogan" />
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    generell() {
      return this.$store.state.router.layout.generell;
    },
  },
};
</script>

<style scoped lang="scss">
.Footer {
  @screen md {
    font-size: 1.2rem;
  }
}
</style>
