<template>

  <div id="overlay" />

  <main>
    <div class="stripes" />

    <div
      v-if="layout"
      v-clean-svgs
      class="bg-slogan"
      v-html="layout.generell?.hintergrund?.svg"
    />

    <Navigation />

    <Dynamic v-if="layout && page?.content" :inject="page.content" />

    <div class="stripes bottom" :class="{'mt-auto': !layout || !page?.content}" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {
    Navigation,
    Dynamic,
  },

  setup() {
    dynamicPage();
  },

  computed: {
    page() {
      return this.$store.state.router.page;
    },
    layout() {
      return this.$store.state.router.layout;
    },
  },

};
</script>

<style lang="scss" scoped>
  main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .stripes {
    height: 1.2rem;
    width: 100%;

    background-image:
      linear-gradient(to right, var(--color-brown), var(--color-brown)),
      linear-gradient(to right, var(--color-purple), var(--color-purple)),
      linear-gradient(to right, var(--color-red), var(--color-red)),
      linear-gradient(to right, var(--color-blue), var(--color-blue));
    background-repeat: no-repeat;
    background-size: 160% 25%, 160% 25%, 130% 25%, 100% 25%;
    background-position: 0 0, 0 33.33%, 0 66.66%, 0 100%;

    transition: background 2.3s;

    .no-anim & {
      background-size: 0% 25%, 0% 25%, 0% 25%, 0% 25%;
    }

    &.bottom {
      transform: rotate(180deg);
    }
  }

  .bg-slogan {
    position: fixed;
    width: 40vw;
    height: 70vh;
    bottom: 0;
    z-index: -1;

    display: none;
    @screen md {
      display: block;
    }

    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
</style>
