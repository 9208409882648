<template>

  <transition-group name="fade">

    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.data.slug"
      :key="component.data.id"
      :payload="component.data"
      class="fade-item"
    >
      <h2>Unable to load component «{{ component.component }}».</h2>
    </component>

    <Footer key="footer" class="fade-item mt-auto" />

  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

import Footer from '@/components/structure/Footer.vue';

export default {
  components: {

    Footer,
    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Tiles: defineAsyncComponent(() => import('@/components/pages/Tiles.vue')),
    Kachel: defineAsyncComponent(() => import('@/components/pages/Kachel.vue')),
    About: defineAsyncComponent(() => import('@/components/pages/About.vue')),
    Members: defineAsyncComponent(() => import('@/components/pages/Members.vue')),
    MembersSuccess: defineAsyncComponent(() => import('@/components/pages/MembersSuccess.vue')),
    Questions: defineAsyncComponent(() => import('@/components/pages/Questions.vue')),
    Search: defineAsyncComponent(() => import('@/components/pages/Search.vue')),
    Impressum: defineAsyncComponent(() => import('@/components/pages/Impressum.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
